.App {
  height: 100%;
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-desktop-cover {
  display: none;
  height: 100%;
}

.App-mobile-content {
  height: 100%;
  margin: 0 auto;
}

.App-body {
  height: 100%;
}

@media only screen and (min-width: 800px) {
  .App-desktop-cover {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: fixed;
    width: 50%;
    z-index: 0;
  }

  .App-mobile-content {
    margin: 0 0 0 50%;
    max-width: 420px;
    position: relative;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
}
